import React, { useEffect } from 'react';
import { TimelineMax, Sine } from 'gsap';

import loaderAus from '../images/aus_logo_colour.png';
import loaderCan from '../images/can_logo_colour.png';

const Loader = ({ cancelLoader, region }) => {
  useEffect(() => {
    const tl = new TimelineMax({ onComplete: cancelLoader });
    tl.to('.loader__content', 0.5, {
      ease: Sine.easeInOut,
      opacity: 1,
      delay: 1,
    }).to('.loader', 0.5, {
      ease: Sine.easeInOut,
      opacity: 0,
      delay: 3,
    });
  }, []);

  return (
    <div className="loader">
      {region === 'au' && <img src={loaderAus} alt="loader" className="loader__content" />}
      {region === 'ca' && <img src={loaderCan} alt="loader" className="loader__content" />}
    </div>
  );
};

export default Loader;
