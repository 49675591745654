import React, { useState } from 'react';

import TransitionVideo from './transitionVideo';
import Content from './Content/content';
import cms from '../cms.json';

const Main = ({ boys, region }) => {
  const [videoComplete, setVideoComplete] = useState(false);
  const [category, setCategory] = useState('');

  return (
    <React.Fragment>
      <div className="dead-space" />
      {!videoComplete && (
        <TransitionVideo
          setVideoComplete={setVideoComplete}
          region={region}
          boys={boys ? 'boys' : 'sups'}
        />
      )}
      {videoComplete && (
        <Content
          cms={cms}
          boysArray={cms.boys.people}
          supesArray={cms.supes.people}
          boys={boys}
          region={region}
          setCategory={setCategory}
          category={category}
        />
      )}
    </React.Fragment>
  );
};

export default Main;
