/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-new */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';
import { TweenMax, Sine } from 'gsap';
import YouTubeIframeLoader from 'youtube-iframe';
import Slider from './slider';
import Playlist from './playlist';
import sendGaEvent from '../../utils/sendGaEvent';

// Images
import baby from '../../images/laser_baby.png';
import logoAus from '../../images/aus_logo_colour-2.png';
import logoCan from '../../images/can_logo_colour.png';
import logoWhiteAus from '../../images/aus_logo_white.png';
import logoWhiteCan from '../../images/can_logo_white.png';
import background from '../../images/bg_flag.jpg';
import backgroundPayoff from '../../images/background_payoff.jpg';
import bottomBannerCan from '../../images/can_boys_bottom_payoff_1600px.jpg';
import bottomBannerAus from '../../images/aus_boys_bottom_payoff_1600px2.jpg';
import tileBoys from '../../images/tile_boys.jpg';
import tileSupes from '../../images/tile_sups.jpg';

const Content = ({ cms, setCategory, category, boysArray, supesArray, region }) => {
  const onPlayerStateChange = event => {
    if (event.data == 1) {
      sendGaEvent('trailer', 'play');
    } else if (event.data == 2) {
      sendGaEvent('trailer', 'pause');
    } else if (event.data == 0) {
      sendGaEvent('trailer', 'end');
    }
  };

  const loadYoutube = () => {
    YouTubeIframeLoader.load(YT => {
      new YT.Player('video', {
        height: '350',
        width: '630',
        videoId: 'MN8fFM1ZdWo',
        events: { onStateChange: onPlayerStateChange },
      });
    });
  };

  const goBack = () => {
    setCategory('');
    loadYoutube();
  };

  useEffect(() => {
    TweenMax.to('.content', 0.5, {
      ease: Sine.easeInOut,
      opacity: 1,
      delay: 0.5,
    });

    loadYoutube();
  }, []);

  const endVideo = () => {
    sendGaEvent('trailer', 'end');
  };
  const playVideo = () => {
    sendGaEvent('trailer', 'play');
  };
  const pauseVideo = () => {
    sendGaEvent('trailer', 'pause');
  };

  useEffect(() => {
    if (category !== '') {
      window.scrollTo(0, 0);
    }
  }, [category]);
  const handleClick = event => {
    event.preventDefault();
    const clickTag =
      'https://ad.doubleclick.net/ddm/trackclk/N624.159072.SPOTIFY/B24451699.280083894;dc_trk_aid=474609711;dc_trk_cid=135799208;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755}';
    window.open(clickTag);
  };

  return (
    <div className="content">
      {category === '' && (
        <div className="content__wrapper">
          <div className="content__banner">
            <img src={baby} alt="laser baby" className="content__banner-left" />
            {region === 'au' && <img src={logoAus} alt="logo" className="content__banner-right" />}
            {region === 'ca' && <img src={logoCan} alt="logo" className="content__banner-right" />}
          </div>

          <h1 className="margin-bottom">{cms.landing.title}</h1>
          <p className="margin-bottom">{cms.landing.copy}</p>

          <div className="content__tiles">
            <button
              type="button"
              onClick={() => setCategory('boys')}
              onKeyDown={() => setCategory('boys')}
              className="content__tile"
              data-event-label="join the boys button"
            >
              <img className="content__tile-image" src={tileBoys} alt="boys tile" />
              <p className="button">{cms.landing.boysButton}</p>
            </button>

            <button
              type="button"
              onClick={() => setCategory('supes')}
              onKeyDown={() => setCategory('supes')}
              className="content__tile"
              data-event-label="join the supes button"
            >
              <img className="content__tile-image" src={tileSupes} alt="supes tile" />
              <p className="button">{cms.landing.supesButton}</p>
            </button>
          </div>

          <h2 className="margin-bottom">{cms.landing.subtitle}</h2>

          <div className="content__trailer">
            {region === 'ca' && <div id="video" />}
            {region === 'au' && (
              <video
                className="content__video"
                preload="auto"
                width="630"
                height="350"
                controls
                onEnded={endVideo}
                onPlay={playVideo}
                onPause={pauseVideo}
              >
                <source
                  src="https://storage.googleapis.com/amazon-the-boys-adpage/videos/THBY_S2_02083_Trailer_Post_Master_Storm_16x9_Updated%20(1).webm"
                  type="video/webm"
                />
              </video>
            )}
          </div>
          <img src={background} alt="background" className="content__background" />
        </div>
      )}
      {category !== '' && (
        <div>
          <div className="content__wrapper">
            <div className="content__banner">
              <button
                type="button"
                className="back-cta"
                onClick={goBack}
                onKeyDown={goBack}
                data-event-label="go back button"
              >
                Go Back
              </button>
              {region === 'au' && (
                <img src={logoWhiteAus} alt="logo" className="content__logo-white" />
              )}
              {region === 'ca' && (
                <img src={logoWhiteCan} alt="logo" className="content__logo-white" />
              )}
            </div>

            <h1 className="margin-bottom">{cms.landing.title}</h1>
            {category === 'boys' && (
              <div>
                <p className="margin-bottom">{cms.boys.copy}</p>
                <div className="content__payoff-wrapper">
                  <Playlist src={cms.boys.playlist} />
                  <div className="content__payoff-slider">
                    <Slider characters={boysArray} />
                  </div>
                </div>
              </div>
            )}
            {category === 'supes' && (
              <div>
                <p className="margin-bottom">{cms.supes.copy}</p>
                <div className="content__payoff-wrapper">
                  <Playlist src={cms.supes.playlist} />

                  <div className="content__payoff-slider">
                    <Slider characters={supesArray} />
                  </div>
                </div>
              </div>
            )}
          </div>
          <img src={backgroundPayoff} alt="background" className="content__background-payoff" />
          <a href="#" className="content__banner-link" onClick={handleClick}>
            {region === 'au' && (
              <img src={bottomBannerAus} alt="bottom banner" className="content__bottom-banner" />
            )}
            {region === 'ca' && (
              <img src={bottomBannerCan} alt="bottom banner" className="content__bottom-banner" />
            )}
          </a>
        </div>
      )}
    </div>
  );
};
export default Content;
