import React, { useEffect, useState } from 'react';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.min.css';
import Svg from 'react-inlinesvg';
import sendGaEvent from '../../utils/sendGaEvent';

import Arrow from '../../images/svgs/arrow.svg';

const Slider = ({ characters }) => {
  const [swiperSlides, setSwiperSlides] = useState(null);
  const ThumbsSwiper = document.querySelector('.gallery-thumbs');

  const initSwiper = () => {
    const galleryThumbs = new Swiper('.gallery-thumbs', {
      grabCursor: true,
      loop: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      touchRatio: 0.2,
      slideToClickedSlide: true,
      loopedSlides: 4,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    const galleryTop = new Swiper('.gallery-top', {
      spaceBetween: 10,
      loop: true,
      slidesPerView: 1,
      loopedSlides: 4,
      thumbs: { swiper: galleryThumbs },
      grabCursor: true,
    });

    galleryTop.on('slideNextTransitionStart', () => {
      sendGaEvent('next character slide');
      galleryThumbs.slideTo(galleryTop.activeIndex);
    });
    galleryTop.on('slidePrevTransitionStart', () => {
      sendGaEvent('previous character slide');
      galleryThumbs.slideTo(galleryTop.activeIndex);
    });

    galleryThumbs.on('slideNextTransitionStart', () => {
      sendGaEvent('next track slide');
      galleryTop.slideTo(galleryThumbs.activeIndex);
    });
    galleryThumbs.on('slidePrevTransitionStart', () => {
      sendGaEvent('previous track slide');
      galleryTop.slideTo(galleryThumbs.activeIndex);
    });
  };

  const createSwiperSlides = async () => {
    setSwiperSlides(
      characters.map((character, i) => (
        <div className="swiper-slide" key={i}>
          <h1 className="name">{character.name}</h1>
          <img className="image" src={require(`../../images${character.imageUrl}`)} alt="" />
          <p className="copy">{character.copy}</p>
        </div>
      )),
    );
  };

  const slidePrev = () => {
    if (ThumbsSwiper) {
      ThumbsSwiper.swiper.slidePrev();
      sendGaEvent('previous track slide');
    }
  };
  const slideNext = () => {
    if (ThumbsSwiper) {
      ThumbsSwiper.swiper.slideNext();
      sendGaEvent('next track slide');
    }
  };

  useEffect(() => {
    createSwiperSlides().then(() => initSwiper());
  }, []);

  return (
    <React.Fragment>
      <div className="swiper-container gallery-top">
        <div className="swiper-wrapper">{swiperSlides}</div>
      </div>
      <div className="thumbs-container">
        <div className="swiper-container gallery-thumbs">
          <div className="swiper-wrapper">{swiperSlides}</div>
        </div>
        <div
          className="swiper-button-prev"
          onClick={slidePrev}
          onKeyDown={slidePrev}
          role="button"
          tabIndex="0"
        >
          <Svg src={Arrow} className="arrow" />
        </div>
        <div
          className="swiper-button-next"
          onClick={slideNext}
          onKeyDown={slideNext}
          role="button"
          tabIndex="0"
        >
          <Svg src={Arrow} className="arrow" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Slider;
