import React from 'react';

const Playlist = ({ src }) => (
  <React.Fragment>
    <iframe
      src={src}
      width="300"
      height="500"
      frameBorder="0"
      allowTransparency="true"
      allow="encrypted-media"
      title="playlist"
    />
  </React.Fragment>
);

export default Playlist;
