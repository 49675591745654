import React, { useEffect, useRef, useState } from 'react';
import { TweenMax, Sine } from 'gsap';
import Loader from './loader';
import sendGaEvent from '../utils/sendGaEvent';

const TransitionVideo = ({ region, boys, setVideoComplete }) => {
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(null);
  const [time, setTime] = useState(0);
  const videoRef = useRef();

  const watchProgress = () => {
    const video = videoRef.current;
    const timeLeft = video.duration - video.currentTime;

    if (timeLeft < 1) {
      video.removeEventListener('timeupdate', watchProgress);
    }
  };

  const setupVideo = () => {
    const video = videoRef.current;

    if (size) {
      video.src = `https://storage.googleapis.com/amazon-the-boys-adpage/videos/${region}_${size}_the_boys_animation_${boys}_v2.webm`;
    }
    video.currentTime = time;

    video.addEventListener('canplay', () => {
      video.play();
      video.addEventListener('timeupdate', watchProgress);
    });
  };

  const applySize = async newSize => {
    const video = videoRef.current;

    if (size !== newSize && video) {
      setSize(newSize);
      setTime(video.currentTime);
    }
  };

  const checkSize = () => {
    const width = window.innerWidth;
    let currentSize;

    if (width >= 1600) {
      currentSize = '3';
    } else if (width >= 1200 && width < 1600) {
      currentSize = '2';
    } else {
      currentSize = '1';
    }

    applySize(currentSize);
  };

  const cancelLoader = () => {
    setLoading(false);
    checkSize();
    window.addEventListener('resize', checkSize);
    sendGaEvent('animation', 'start');
  };

  const endVideo = () => {
    TweenMax.to('.transition-video', 0.5, {
      ease: Sine.easeInOut,
      opacity: 0,
      onComplete: () => {
        setVideoComplete(true);
        sendGaEvent('animation', 'end');
      },
    });
  };

  useEffect(() => {
    TweenMax.to('.transition-video__video', 0.5, {
      ease: Sine.easeInOut,
      opacity: 1,
      delay: 5.5,
    });
  }, []);

  useEffect(() => {
    setupVideo();
  }, [size]);

  return (
    <div className="transition-video">
      <video
        className="transition-video__video"
        ref={videoRef}
        preload="auto"
        muted
        onEnded={endVideo}
      >
        <source
          src={`https://storage.googleapis.com/amazon-the-boys-adpage/videos/${region}_${size}_the_boys_animation_${boys}_v2.webm`}
          type="video/webm"
        />
      </video>
      {!loading && <Loader cancelLoader={cancelLoader} region={region} />}
    </div>
  );
};

export default TransitionVideo;
