import React from 'react';
import Helmet from 'react-helmet';
import uuid from 'uuid/v1';

const googleAnalytics = (lang, pagePath) => {
  const gaLangIds = {
    au: process.env.GATSBY_GA_ID_AU,
    ca: process.env.GATSBY_GA_ID_CA,
  };

  const correctLangID = gaLangIds[lang];

  return [
    <script
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${correctLangID}`}
      key={uuid()}
    />,
    <script key={uuid()}>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', '${correctLangID}', {
          'anonymize_ip' : true,
          'page_path': "/${lang}/${pagePath}"
        });
      `}
    </script>,
  ];
};

export default function Analytics({ lang, pageUrl }) {
  return <Helmet>{googleAnalytics(lang, pageUrl)}</Helmet>;
}
